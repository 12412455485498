import styles from '../css/app.css'


import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

let application = null

if (module.hot) {
  module.hot.accept() // tell hmr to accept updates

  if (module.hot.data) {
    application = module.hot.data.application // re-use old application if one was passed after update
  }
}

if (!application) {
  application = Application.start() // if no application so far, start it
}

if (module.hot) {
  module.hot.dispose(data => {
    data.application = application // on disposal of the old version (before reload), pass the old application to the new version
  })
}

const context = require.context("./controllers", true, /\.js$/)
application.load(definitionsFromContext(context))
