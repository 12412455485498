import { Controller } from "stimulus"


export default class extends Controller {
  static targets = [ "trigger", "menu" ]
  static isOpen

  initialize() {
    this.isOpen = false
  }

  toggle() {
    this.isOpen = !this.isOpen
    this.triggerTarget.classList.toggle('active', this.isOpen)
    this.menuTarget.classList.toggle('open', this.isOpen)
  }
}
