// Used Sonntagszopf as an alias to honeypot to be not easily recognisable
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "trap" ]

  initialize() {
    this.trapTarget.classList.add("sonntagszopf")
  }

  handleSubmit(event) {
    event.preventDefault()
    var form = event.target
    //see if something fell into the trap
    if (this.trapTarget.value !== "") {
      //abandon process
      form.reset()
    } else {
      form.submit()
    }
  }
}
