import { Controller } from "stimulus"
import L from "leaflet"

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: '/assets/marker-icon.svg',
  iconUrl: '/assets/marker-icon.svg',
  shadowUrl: '/assets/marker-shadow.png',
});


export default class extends Controller {

  connect() {
      let map = L.map(this.element.id)

      // add the Wikimedia tiles
      L.tileLayer('https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}@2x.png', {
        attribution: '&copy; <a href="https://maps.wikimedia.org" target="_blank" rel="noreferrer">Wikimedia</a>'
      }).addTo(map)

      let marker = L.marker([this.data.get('latitude'), this.data.get('longitude')]).addTo(map)


      let data = this.data
      // wait a bit to set the view, otherwise it's not centered properly...
      setTimeout(function() {
          map.setView([data.get('latitude'), data.get('longitude')], 14)
      }, 250)
  }
}
