import { Controller } from "stimulus"

let openMenu

export default class extends Controller {
  static targets = [ "trigger", "menu" ]

  initialize() {
    this.isOpen = false
  }

  toggle() {
    if(openMenu == this) {
      openMenu = null
    }

    if(openMenu) {
      openMenu.toggle()
    }

    this.isOpen = !this.isOpen
    if(this.isOpen) {
      openMenu = this
    }

    this.triggerTarget.classList.toggle('active', this.isOpen)
    this.menuTarget.classList.toggle('open', this.isOpen)
  }
}
