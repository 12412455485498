import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "loadButton" ]

  loadMore() {
    let page = parseInt(this.data.get('page'))

    fetch('/api/blog.json?page=' + page)
    .then(response => response.json())
    .then(json => {
      page++
      this.data.set('page', page)
      json.data.forEach((articleJson) => {
        this.loadButtonTarget.insertAdjacentHTML( 'beforebegin', this.articleTemplate(articleJson))
      })
      if(json.meta.pagination.current_page == json.meta.pagination.total_pages) {
        this.loadButtonTarget.classList.add('hidden')
      }
    })
  }

  articleTemplate(article) {
    let html = `<div class="mb-6">`

    html += `<article class="flex flex-col md:flex-row items-center w-full sm:w-auto sm:flex-basis-1/2 sm:mx-2" >`

    let cssClasses = "flex-1 self-stretch px-2 mx-2 pt-4 pb-2 bg-brand-secondary md:mr-0"

    if(article.articleImageUrl != null) {
      html += `
      <img
        class="shadow-md h-auto z-20 w-full md:w-auto"
        title="${article.articleImageTitle}"
        alt="${article.articleImageAlt}"
        src="${article.articleImageUrl}"
      />`
      cssClasses += " md:-ml-20 md:pl-22"
    } else {
      cssClasses += " md:pl-2 md:w-full"
    }

    html +=
    `
        <div class="${ cssClasses }">
          <h2 class="font-bold text-lg hyphens-auto">${ article.title }</h2>
          <p class="mt-1 text-sm line-above-sm">
            Veröffentlicht am <time datetime="${ article.dateTime }">${ article.dateTime }</time>
          </p>
    `

    if(article.articleLead != null) {
      html += `<p class="mb-2 hyphens-auto">${ article.articleLead }</p>`
    }

    html += `
          <a href="${ article.url }" class="btn block md:inline-block">Artikel lesen</a>
        </div>
      </article>
    </div>
    `

    return html
  }
}
