import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "content", "indicator" ]

  toggle() {
    this.contentTarget.classList.toggle('hidden')
    this.indicatorTarget.classList.toggle('rotate-180')
  }
}
