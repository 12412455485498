import { Controller } from "stimulus"
import Chocolat from 'chocolat'

export default class extends Controller {
  static targets = [ "image" ]

  connect() {
    let images = []
    for(let imageNode of this.imageTargets) {
      images.push({
        src: imageNode.getAttribute('href'),
        title: imageNode.getAttribute('title')
      })
    }

    const { api } =  Chocolat(images, {
      loop: true
    })
    this.api = api
  }

  open(event) {
    event.preventDefault()
    const index = parseInt(event.currentTarget.getAttribute("data-lightbox-index"))
    this.api.open(index)
    return false
  }
}
