import { Controller } from "stimulus"


export default class extends Controller {
  static targets = [ "progressBar", "remainingDays", "statusText" ]

  initialize() {
    let campaign_id = this.data.get('id')
    // do the fetch
    fetch('https://goa-schweiz.mitcuora.ch/api/campaigns/'+campaign_id+'/status')
    .then(response => response.json())
    .then(json => {

      this.updateProgressBar(json.progress_in_percent)

      switch(json.status) {
        case 'planned':
          this.statusTextTarget.innerHTML = "Aktion noch nicht gestartet."
          break
        case 'running':
          this.updateRemainingDays(json.end_at)
          break
        case 'finished':
          this.statusTextTarget.innerHTML = "Aktion ist beendet."
          break
      }
    })
  }

  updateProgressBar(progress_in_percent) {
    let progress = parseFloat(progress_in_percent).toFixed(2)+"%"
    this.progressBarTarget.style.width = progress
    this.progressBarTarget.innerHTML = progress
  }

  updateRemainingDays(end_date) {
    let remaining_time = new Date(end_date) - new Date()
    let remaining_days = Math.floor(remaining_time / (1000 * 3600 * 24))
    let remaining_days_text = ""
    if(remaining_days > 1) {
      remaining_days_text = remaining_days + " Tage"
    } else if (remaining_days == 1) {
      remaining_days_text = remaining_days + " Tag"
    } else if (remaining_days < 1) {
      remaining_days_text = "weniger als 1 Tag"
    }
    this.remainingDaysTarget.innerHTML = remaining_days_text
  }
}
